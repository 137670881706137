let origin = "https://future.zhuneng.cn";
//签到状态
const signStatusUrl = `/accApi/accPoints/dailyPerf/getSignInStatus`;
//签到
const signUrl = `/accApi/accPoints/dailyPerf/doSignIn`;
//每日任务
const dayMaskUrl = `/accApi/accPoints/dailyPerf/getSignInStatus`;
//是否显示评论
const isShowSign = `/accApi/accPoints/application/getFunctionSetting`;
export { signStatusUrl, signUrl, dayMaskUrl, isShowSign };
